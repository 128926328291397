import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/HighlightOff';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import ChangeIcon from '@material-ui/icons/Autorenew';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getCisCommCode, getCustomData } from '../../service/CommonService';
import { getAssetReturnAskList, updateAssetReturnAsk, assetReturnAsk } from '../../service/rental/RentalAssetService';
import { errorMsgCheck, resultToIbSheet, dateToFormat, InsertComma, managerCheck, ComIBSheetComboNull, SheetParamData5  } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';

const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, KYContInfoCheckPopupStore } = CommonStore;

class ApiContractReturnProc extends Component {
	constructor(props) {
		super(props);

		this.sheet = null;
		this.sheetData = {
			id: 'ApiContractReturnProc', //sheet id
			initData: 'admin/Init-ApiContractReturnProc',
			width: '100%',
			height: '300px',
			sheetRendered: (sheet) => {
				this.sheet = sheet;
				this.sheet.ShowFilterRow();

				//공통코드 조회
				Promise.all([getCisCommCode('04'), getCisCommCode('14'), getCisCommCode('30')])
					.then((r) => {
						let [code04, code14, code30] = r;

						this.setState({
							delMethodList: code04,
							returnStatusList: code14,
							centerCodeList: code30, // 센터 코드
						});

						ComIBSheetComboNull('0', sheet, 'delMethod', code04, 'title', 'value', '', '');
						ComIBSheetComboNull('0', sheet, 'centerCd', code30, 'title', 'value', '', '');
						sheet.SetColProperty('delTime', {
							ComboText: '|10시|11시|12시|13시|14시|15시|16시|17시|18시',
							ComboCode: '|10|11|12|13|14|15|16|17|18'
						});
					})
					.catch((err) => {
						errorMsgCheck(err);
					});
			},
		};

		window[`${this.sheetData.id}_OnDblClick`] = (row, col) => {
			const data = this.sheet.GetRowData(row);

			this.setDetailData(data);
			
		};
	}

	state = {
		sCustNo: sessionStorage.authCode > 1 ? sessionStorage.custNo : '',
		sCustNm: sessionStorage.authCode > 1 ? sessionStorage.custNm : '',
		sReturnStatus: '',
		sAskUserId: '',
		sAskUserNm: '',
		sUserChgId: sessionStorage.authCode > 1 ? '' : sessionStorage.userId,
		sUserChgNm: sessionStorage.authCode > 1 ? '' : sessionStorage.userName,
		sAsstNo: '',

		delMethodList: [],
		returnStatusList: [],
		centerCodeList: [],
		
		/* 필드데이터 */
		returnAskNo: '',
		returnStatusCd: 1,
		returnStatus: '',
		custNo: '',
		custNm: '',
		cancelFee: '',
		asstNo: '',
		viewAsstNo: '', /*추가*/
		itemId: '',
		contNo: '',
		askUser: '',
		returnHopeDate: '',
		returnRmk: '',
		postCode: '',
		postAddr: '',
		postSddr: '',
		addrUser: '',
		addrTelno: '',
		addrHpno: '',
		delRmk: '',
		//delMethod: '',
		delRealDate: '',
		askRmk: '',
		cancelRmk: '',
		custReqNo: '', // api v2 고객요청번호

		/* 회수정보 */
		delMethod: '',
		delDate: moment(),
		delTime: '',
		centerCd: '',

		/* 시스템정보 */
		disabled: true,
		disabledForRtnAskBulkBtn: true,

		isOpenModifyModal: false, // 모달열기
		modifyModalType : '', // 모달유형
		modifyModalText : '', // 모달이름
		bulkChangeDialogValue: '', // 모달변경값

		contInfoBtnShow: 'none', // 계약정보확인 버튼 활성화 여부

	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	dateHandler = (date, target) => {
		if(target == 'delDate') {
			this.setState({ delDate: date });
		} else if(target == 'bulkChangeDialogValue') {
			this.setState({ bulkChangeDialogValue: date });
		}
	};

	handleChange = (e, length) => {
		if (length != undefined && length != null && length > 0) {
			if (length < e.target.value.length) {
				ModalStore.openModal(`해당값은 ${length}자를 넘을 수 없습니다.`);
				return;
			}
		}
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});

		if (e.target.name === 'spotSelectRadio') {
			this.setState({
				chgSpotNm: '',
				chgSpotId: '',
			});
		}
	};

	searchCustomer = () => {
		//관리자만 조회할 수 있다.
		//if(sessionStorage.authCode <= 1){
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
		//}
	};

	searchUser = () => {
		UserPopupStore.openPopup(null, sessionStorage.authCode > 1 ? sessionStorage.custNo : '', this.handleUserSearch);
	};

	searchSpot = () => {
		CustSearchPopupStore.openPopup('2', null, this.state.custNo, this.handleSpotClose);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sUserId: item.userId,
				sUserNm: item.userName,
			});
		}
	};

	getSearchParam = () => {
		// 대표고객이면서 고객관리자인 경우 현재 대표고객으로 조회시 하위 고객사까지 조회 가능하다
		var repYn = '';
		if (sessionStorage.custNo === sessionStorage.repCustNo && sessionStorage.custNo === this.state.sCustNo && sessionStorage.authCode === '2') {
			repYn = 'Y';
		} else {
			repYn = 'N';
		}

		let param = {
			returnStatus: this.state.sReturnStatus,
			custNo: this.state.sCustNo,
			asstNo: this.state.sAsstNo,
			askUser: this.state.sAskUserId,
			userChg: this.state.sUserChgId,
			repYn: repYn, // 대표고객이 하위 고객사 전체 조회 가능함 20190716 kkj
		};

		return param;
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	handleSpotClose = (item) => {
		if (item === undefined) return;
		this.setState({
			addrSeq: item.addrSeq,
			chgSpotId: item.spotId,
			chgSpotNm: item.spotNm,
		});
	};

	getReturnAskList = () => {
		let param = this.getSearchParam();

		ProgressStore.activeProgress();

		getAssetReturnAskList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

				this.setState({
					custNo: '', //고객번호
					ordeNo: '',
					appList: [],
					delStatus: '',
					activeStep: '',
				});
				//인풋필드 초기화
				this.setEmptyField();
				this.resultListEditableControl(); 
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	setEmptyField = () => {
		this.setState({
			returnAskNo: '',
			returnStatusCd: 1,
			returnStatus: '',
			custNo: '',
			custNm: '',
			cancelFee: '',
			asstNo: '',
			viewAsstNo: '',
			itemId: '',
			contNo: '',
			askUser: '',
			returnHopeDate: '',
			returnRmk: '',
			postCode: '',
			postAddr: '',
			postSddr: '',
			addrUser: '',
			addrTelno: '',
			addrHpno: '',
			delRmk: '',
			delMethod: '',
			delRealDate: '',
			delTime: '',
			askRmk: '',
			cancelRmk: '',
			custReqNo: '',
			disabled: true,
			centerCd: '',
		});
	};

	delMethodName = (delType) => {
		if (!delType) return;

		let name = '';
		let methodList = this.state.delMethodList;

		for (var i in methodList) {
			if (methodList[i].value === delType) {
				name = methodList[i].title;
			}
		}

		return name;
	};

	setDetailData = (data) => {
		// console.log(data);
		this.setState(
			{
				returnAskNo: data.returnAskNo,
				returnStatusCd: data.returnStatusCd,
				returnStatus: data.returnStatus,
				custNo: data.custNo,
				custNm: data.custNm,
				cancelFee: data.cancelFee,
				asstNo: data.asstNo,
				viewAsstNo: data.viewAsstNo,
				itemId: data.itemId,
				contNo: data.contNo,
				askUser: data.askUser,
				returnHopeDate: data.returnHopeDate,
				returnRmk: data.returnRmk,
				postCode: data.postCode,
				postAddr: data.postAddr,
				postSddr: data.postSddr,
				addrUser: data.addrUser,
				addrTelno: data.addrTelno,
				addrHpno: data.addrHpno,
				delRmk: data.delRmk,
				delMethod: data.delMethod,
				delRealDate: data.delRealDate,
				delTime: data.delTime,
				askRmk: !data.askRmk ? data.delRmk : data.askRmk,
				cancelRmk: data.cancelRmk,
				disabled: data.returnStatusCd !== '1' ? true : false,
				custReqNo: data.custReqNo, // api v2 고객요청번호
				centerCd: data.centerCd,
			},
			() => {
				this.kyContInfoCheckBtnShowControl();
			},
		);
	};

	contInfoCheck = () => {
		KYContInfoCheckPopupStore.openPopup(this.state.custNo, this.state.custReqNo);
	};

	kyContInfoCheckBtnShowControl = () => {
		getCustomData({ custNo: this.state.custNo, customCode: 'CT033' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					contInfoBtnShow: r[0].customWord,
				});
			} else {
				this.setState({
					contInfoBtnShow: 'none',
				});
			}
		});
	};

	cancelReturnAsk = () => {
		let param = {
			returnAskNo: this.state.returnAskNo,
			returnStatus: '4', //취소
			cancelRmk: this.state.cancelRmk,
		};

		ProgressStore.activeProgress();
		updateAssetReturnAsk(param)
			.then(() => {
				ModalStore.openModal('취소 되었습니다.');
				ProgressStore.deactiveProgress();
			})
			.then(() => {
				//재조회
				this.getReturnAskList();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	returnAsk = () => {
		if (!this.state.returnAskNo) {
			ModalStore.openModal('재조회하여 다시 요청해 주세요.');
			return;
		}

		let param = {
			custNo: this.state.custNo,
			deptCode: '04', //OA를 기본으로 한다. 추후 팀 추가되면 시스템화 하는걸로
			contNo: this.state.contNo,
			asstNo: this.state.asstNo,
			itemId: this.state.itemId,
			returnAskNo: this.state.returnAskNo,
			returnStatus: '2', //반납접수
			delMethod: this.state.delMethod,
			delRealDate: this.state.delDate.format('YYYY-MM-DD'),
			delTime: this.state.delTime,
			askRmk: this.state.askRmk,
			cancelFee: this.state.cancelFee,
			addrSeq: sessionStorage.addrSeq,
			centerCd: this.state.centerCd,
		};

		ProgressStore.activeProgress();
		updateAssetReturnAsk(param)
			.then(() => {
				ModalStore.openModal('회수의뢰가 완료 되었습니다.');
				ProgressStore.deactiveProgress();
			})
			.then(() => {
				//재조회
				this.getReturnAskList();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	bulkReturnAsk = () => {

		const FindCheckedRow = this.sheet.FindCheckedRow('chk'); // 체크건 추출

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		const sheetParam = SheetParamData5(this.sheet, null, true);
		if (sheetParam.data.length === 0) return;

		if(sheetParam.data.filter(o => o.returnStatusCd != '1').length > 0) {
			ModalStore.openModal('신청완료가 아닌 반납상태가 존재합니다.');
			return;
		}

		ConfirmModalStore.openModal(`${sheetParam.data.length}건에 대하여 일괄회수의뢰를 진행하시겠습니까?`, () => {

			const params = sheetParam.data.map((o) => {
				return { ...o, 
					returnStatus: '2', 
					deptCode: '04',
					delRealDate: this.state.delDate.format('YYYY-MM-DD'),
					addrSeq: sessionStorage.addrSeq,
				};
			});

			ProgressStore.activeProgress();
			assetReturnAsk(params)
				.then(() => {
					ModalStore.openModal('일괄회수의뢰가 완료 되었습니다.');
					ProgressStore.deactiveProgress();
				})
				.then(() => {
					//재조회
					this.getReturnAskList();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		});
	};

	cancelHandle = () => {
		if (!this.state.cancelRmk) {
			ModalStore.openModal('반납 취소 사유를 입력해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('반납요청을 취소 하시겠습니까?', this.cancelReturnAsk);
	};

	returnAskHandle = () => {
		if (!this.state.delMethod) {
			ModalStore.openModal('회수방법을 선택해 주세요.');
			return;
		}
		if (!this.state.delDate) {
			ModalStore.openModal('회수일자를 지정해 주세요.');
			return;
		}
		if (!this.state.delTime) {
			ModalStore.openModal('시간을 선택해 주세요.');
			return;
		}
		if (!this.state.centerCd) {
			ModalStore.openModal('센터를 선택해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('회수의뢰를 진행 하시겠습니까?', this.returnAsk);
	};
	
	resultListEditableControl = () => {
		const sheet = window[this.sheetData.id];
		if (this.state.sReturnStatus === '1') {
			// 접수완료건에만 그리드 입력필드 활성화
			sheet.SetColEditable('delMethod', 1);
			sheet.SetColEditable('delRealDate', 1);
			sheet.SetColEditable('delTime', 1);
			sheet.SetColEditable('centerCd', 1);
			sheet.SetColEditable('delRmk', 1);

			this.setState({ disabledForRtnAskBulkBtn: false });
		} else {
			sheet.SetColEditable('delMethod', 0);
			sheet.SetColEditable('delRealDate', 0);
			sheet.SetColEditable('delTime', 0);
			sheet.SetColEditable('centerCd', 0);
			sheet.SetColEditable('delRmk', 0);

			this.setState({ disabledForRtnAskBulkBtn: true });
		}

	};

	handleBulkChangeDialogOpen = (target) => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}
		let modifyModalText = '';
		if(target == 'centerCd') {
			modifyModalText = '센터';
		} else if(target == 'delMethod') {
			modifyModalText = '회수방법';
		} else if(target == 'delRealDate') {
			modifyModalText = '회수일자';
		} else if(target == 'delTime') {
			modifyModalText = '회수시간';
		} else if(target == 'delRmk') {
			modifyModalText = '회수요청사항';
		}
		this.setState({ isOpenModifyModal: true, modifyModalType: target, modifyModalText });
	};

	handleBulkChangeDialogClose = () => {
		this.setState({ isOpenModifyModal: false })
		this.setState({ bulkChangeDialogValue: '' });
		this.setState({ modifyModalText: '' });
		this.setState({ modifyModalType: '' });
	};

	handleBulkChangeDialogProc = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		let value = '';
		if(this.state.modifyModalType == 'delRealDate') {
			value = this.state.bulkChangeDialogValue.format('YYYY-MM-DD');
		} else {
			value = this.state.bulkChangeDialogValue;
		}

		for (var idx = 0; idx < checkRows.length; idx++) {
			sheet.SetCellValue(checkRows[idx], this.state.modifyModalType, value);
		}

		ModalStore.openModal(`선택된 ${checkRows.length}건이 성공적으로 변경되었습니다.`);
		this.setState({ isOpenModifyModal: false })
		this.setState({ bulkChangeDialogValue: '' });
		this.setState({ modifyModalText: '' });
		this.setState({ modifyModalType: '' });
	};

	makeUserChgInput = () => {
		if (sessionStorage.authCode > '1') {
			return (
				<tr>
					<th>신청자</th>
					<td>
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sAskUserId}
							placeholder="ID"
							onChange={(e) => this.setState({ sAskUserId: e.target.value })}
						/>
						<IconButton onClick={() => this.searchUser('')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sAskUserNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sAskUserNm: e.target.value })}
						/>
					</td>
					<td />
					<td />
				</tr>
			);
		} else {
			return (
				<tr>
					<th>신청자</th>
					<td>
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sAskUserId}
							placeholder="ID"
							onChange={(e) => this.setState({ sAskUserId: e.target.value })}
						/>
						<IconButton onClick={() => this.searchUser('')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sAskUserNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sAskUserNm: e.target.value })}
						/>
					</td>
					<th>지원담당자</th>
					<td colSpan="3">
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sUserChgId}
							placeholder="ID"
							onChange={(e) => this.setState({ sUserChgId: e.target.value })}
						/>
						<IconButton onClick={() => this.searchUser('user')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sUserChgNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sUserChgNm: e.target.value })}
						/>
					</td>
				</tr>
			);
		}
	};

	render() {
		const dateInput = <input type="text" style={{ width: 120 }} />;

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mm30">
								<h2>API 일괄 반납 생성 요청</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getReturnAskList}>
										<span>조회</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />
												<IconButton onClick={this.searchCustomer} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>반납상태</th>
											<td>
												<select style={{ width: 120 }} onChange={(e) => this.setState({ sReturnStatus: e.target.value })}>
													<option value="">전체</option>
													{this.state.returnStatusList.map((item) => (
														<option value={item.value}>{item.title}</option>
													))}
												</select>
											</td>
											<th>자산번호</th>
											<td>
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sAsstNo}
													onChange={(e) => this.setState({ sAsstNo: e.target.value })}
												/>
											</td>
										</tr>
										{this.makeUserChgInput()}
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={() => this.handleBulkChangeDialogOpen('delMethod')}
										disabled={this.state.disabledForRtnAskBulkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										회수방법일괄변경
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={() => this.handleBulkChangeDialogOpen('delRealDate')}
										disabled={this.state.disabledForRtnAskBulkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										회수일자일괄변경
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={() => this.handleBulkChangeDialogOpen('delTime')}
										disabled={this.state.disabledForRtnAskBulkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										회수시간일괄변경
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={() => this.handleBulkChangeDialogOpen('delRmk')}
										disabled={this.state.disabledForRtnAskBulkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										회수요청사항일괄변경
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={() => this.handleBulkChangeDialogOpen('centerCd')}
										disabled={this.state.disabledForRtnAskBulkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										센터일괄변경
									</Button>
									<Button
										color="secondary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={this.bulkReturnAsk}
										disabled={this.state.disabledForRtnAskBulkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										일괄회수요청
									</Button>
								</div>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline" style={{ display: 'inline' }}>
										반납요청리스트
									</p>
									<IBSheet {...this.sheetData} />
								</div>
								<div style={{ fontSize: 14, color: '#d41826' }}>
									<span>* 선택 시 더블클릭</span>
								</div>
							</div>
							<div className="writeType03_noborder" style={{ float: 'left', width: '49%' }}>
								<p className="headline" style={{ display: 'inline' }}>
									요청정보
								</p>
								<table>
									<colgroup>
										<col style={{ width: 100 }} />
										<col style={{ width: 200 }} />
										<col style={{ width: 90 }} />
										<col style={{ width: 160 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} value={this.state.custNm} />
											</td>
											<th style={{ color: '#E91E63' }}>위약금</th>
											<td>{this.state.cancelFee ? <input type="text" readOnly={true} value={InsertComma(this.state.cancelFee) + '원'} /> : ''}</td>
										</tr>
										<tr>
											<th>자산번호</th>
											<td>
												<input type="text" readOnly={true} value={this.state.viewAsstNo} />
											</td>
											<th>계약번호</th>
											<td>
												<input type="text" readOnly={true} value={this.state.contNo} />
											</td>
										</tr>
										<tr>
											<th style={{ color: '#E91E63' }}>반납상태</th>
											<td>
												<input type="text" readOnly={true} value={this.state.returnStatus} />
											</td>
											<th>회수희망일자</th>
											<td>
												<input type="text" readOnly={true} value={this.state.returnHopeDate} />
											</td>
										</tr>
										<tr>
											<th>반납사유</th>
											<td colSpan="3">
												<input type="text" readOnly={true} value={this.state.returnRmk} />
											</td>
										</tr>
										<tr>
											<th>회수지주소</th>
											<td colSpan="3">
												<input type="text" readOnly={true} value={this.state.postAddr + ' ' + this.state.postSddr} />
											</td>
										</tr>
										<tr>
											<th>담당자</th>
											<td colSpan="3">
												<input type="text" readOnly={true} value={this.state.addrUser + ' ( ' + this.state.addrTelno + ' / ' + this.state.addrHpno + ' )'} />
											</td>
										</tr>
										<tr>
											<th>회수요청사항</th>
											<td colSpan="3">
												<input type="text" readOnly={true} value={this.state.delRmk} />
											</td>
										</tr>
										{this.state.returnStatusCd === '2' || this.state.returnStatusCd === '3' ? (
											<tr>
												<th style={{ color: '#E91E63' }}>회수확정일자</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delRealDate + ' (' + this.state.delTime + '시)'} />
												</td>
												<th style={{ color: '#E91E63' }}>회수방법</th>
												<td>
													<input type="text" readOnly={true} value={this.delMethodName(this.state.delMethod)} />
												</td>
											</tr>
										) : (
											<tr>
												<th>반납취소사유</th>
												<td colSpan="3">
													<input
														type="text"
														readOnly={this.state.disabled}
														style={{ border: this.state.disabled ? 0 : '1px solid #efefef' }}
														onChange={(e) => this.setState({ cancelRmk: e.target.value })}
														value={this.state.cancelRmk}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="writeType03_acc" style={{ float: 'right', width: '49%' }}>
								<p className="headline">회수정보</p>
								<table>
									<colgroup>
										<col style={{ width: 100 }} />
										<col style={{ width: 120 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 220 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>회수방법</th>
											<td>
												<select value={this.state.delMethod} onChange={(e) => this.setState({ delMethod: e.target.value })} disabled={this.state.disabled}>
													<option value="">선택</option>
													{this.state.delMethodList.map((item) => (
														<option value={item.value}>{item.title}</option>
													))}
												</select>
											</td>
											<th>회수일자</th>
											<td>
												{this.state.returnStatusCd === '1' ? (
													<DatePicker
														customInput={<input type="text" style={{ width: 120 }} />}
														dateFormat="YYYY-MM-DD"
														onChange={(date) => this.dateHandler(date, 'delDate')}
														selected={this.state.delDate}
														isClearable={!this.state.disabled}
														disabled={this.state.disabled}
														showMonthDropdown
														showYearDropdown
														dropdownMode="select"
													/>
												) : (
													<input type="text" readOnly={true} style={{ width: 100 }} value={this.state.delRealDate} />
												)}
												<select
													value={this.state.delTime}
													style={{ width: 60, marginLeft: 5 }}
													onChange={(e) => this.setState({ delTime: e.target.value })}
													disabled={this.state.disabled}
												>
													<option value="">선택</option>
													<option value="10">10시</option>
													<option value="11">11시</option>
													<option value="12">12시</option>
													<option value="13">13시</option>
													<option value="14">14시</option>
													<option value="15">15시</option>
													<option value="16">16시</option>
													<option value="17">17시</option>
													<option value="18">18시</option>
												</select>
											</td>
										</tr>
										<tr>
											<th>센터정보</th>
											<td colSpan="3">
												<select value={this.state.centerCd} name="centerCd" onChange={this.handleChange} disabled={this.state.disabled}>
													<option value="">선택</option>
													{this.state.centerCodeList.map((item) => (
														<option value={item.value}>{item.title}</option>
													))}
												</select>
											</td>
										</tr>
										<tr>
											<th>회수요청사항</th>
											<td colSpan="3">
												<input
													type="text"
													value={this.state.askRmk}
													disabled={this.state.disabled}
													onChange={(e) => this.setState({ askRmk: e.target.value })}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ clear: 'both', marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									style={{ alignItems: 'initial' }}
									onClick={this.cancelHandle}
									disabled={this.state.disabled}
								>
									<CancelIcon style={{ fontSize: 20, marginRight: 5 }} />
									반납취소
								</Button>
								<span style={{ display: this.state.contInfoBtnShow }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.contInfoCheck}
										disabled={this.state.custReqNo ? false : true}
									>
										<CheckCircleOutlineOutlinedIcon style={{ fontSize: 20, marginRight: 5 }} />
										계약정보 확인
									</Button>
								</span>
								<Button
									color="primary"
									variant="contained"
									size="small"
									style={{ marginLeft: 10, alignItems: 'initial' }}
									onClick={this.returnAskHandle}
									disabled={this.state.disabled}
								>
									<ReturnIcon style={{ fontSize: 20, marginRight: 5 }} />
									회수요청
								</Button>
							</div>
						</div>
					</div>
				</div>
				<Dialog onClose={() => this.handleBulkChangeDialogClose('center')} open={this.state.isOpenModifyModal}>
					<DialogTitle>{this.state.modifyModalText} 일괄 변경</DialogTitle>
					<div style={{ margin: '10px', textAlign: 'center' }}>
						<div style={{ display: this.state.modifyModalType == 'centerCd' ? 'block' : 'none' }}>
							<select
								name="bulkChangeDialogValue"
								value={this.state.bulkChangeDialogValue}
								onChange={this.handleChange}
								style={{ border: '1px solid #dddddd', height: '25px' }}
							>
								<option value="">빈값</option>
								{this.state.centerCodeList.map((item) => (
									<option value={item.value}>{item.title}</option>
								))}
							</select>
						</div>
						<div style={{ display: this.state.modifyModalType == 'delMethod' ? 'block' : 'none' }}>
							<select
								name="bulkChangeDialogValue"
								value={this.state.bulkChangeDialogValue}
								onChange={this.handleChange}
								style={{ border: '1px solid #dddddd', height: '25px' }}
							>
								<option value="">빈값</option>
								{this.state.delMethodList.map((item) => (
									<option value={item.value}>{item.title}</option>
								))}
							</select>
						</div>
						{this.state.modifyModalType == 'delRealDate' ? 
						<div>
							<DatePicker
								customInput={<input type="text" style={{ width: 120 }} />}
								dateFormat="YYYY-MM-DD"
								onChange={(date) => this.dateHandler(date, 'bulkChangeDialogValue')}
								selected={this.state.bulkChangeDialogValue}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								inline
							/>
						</div>
						: ''}
						<div style={{ display: this.state.modifyModalType == 'delTime' ? 'block' : 'none' }}>
							<select
								name="bulkChangeDialogValue"
								value={this.state.bulkChangeDialogValue}
								onChange={this.handleChange}
								style={{ border: '1px solid #dddddd', height: '25px' }}
							>
								<option value="">빈값</option>
								<option value="10">10시</option>
								<option value="11">11시</option>
								<option value="12">12시</option>
								<option value="13">13시</option>
								<option value="14">14시</option>
								<option value="15">15시</option>
								<option value="16">16시</option>
								<option value="17">17시</option>
								<option value="18">18시</option>
							</select>
						</div>
						<div style={{ display: this.state.modifyModalType == 'delRmk' ? 'block' : 'none' }}>
							<input type="text" value={this.state.bulkChangeDialogValue} onChange={(e) => this.setState({ bulkChangeDialogValue: e.target.value })} />
						</div>
						<Button color="primary" variant="contained" size="small" onClick={() => this.handleBulkChangeDialogProc()} style={{ margin: '10px' }}>
							<ChangeIcon style={{ fontSize: 20, marginRight: 5 }} />
							일괄변경
						</Button>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(ApiContractReturnProc);
